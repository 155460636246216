import { createGlobalStyle } from 'styled-components';
import { respondTo } from '../../theme/mixin';

const GlobalStyle = createGlobalStyle`

// --------------- *
// Custom fonts
// --------------- *

// Font Wurth
@font-face {
  font-family: 'fontWurth';
  src: url(${process.env.PUBLIC_URL + '/fonts/wuerthbook_webfont3.woff2'});
  font-weight: 350;
}
@font-face {
  font-family: 'fontWurth';
  src: url(${process.env.PUBLIC_URL + '/fonts/wuerthbold_webfont3.woff2'});
  font-weight: bold;
}
@font-face {
  font-family: 'fontWurth';
  src: url(${process.env.PUBLIC_URL + '/fonts/wuerthextraboldcond_webfont3.woff2'});
  font-weight: 800;
}
@font-face {
  font-family: 'fontWurth';
  src: url(${process.env.PUBLIC_URL + '/fonts/wuerthbook_webfont3.woff2'});
  font-weight: 400;
}

// Font Hyundai
@font-face {
  font-family: 'fontHyundai';
  src: url(${process.env.PUBLIC_URL + '/fonts/fontHyundaiRegular.woff2'});
  font-weight: 400;
}
@font-face {
  font-family: 'fontHyundai';
  src: url(${process.env.PUBLIC_URL + '/fonts/fontHyundaiMedium.woff2'});
  font-weight: 500;
}
@font-face {
  font-family: 'fontHyundai';
  src: url(${process.env.PUBLIC_URL + '/fonts/fontHyundaiBold.woff2'});
  font-weight: 700;
}

// --------------- *
// Global styles
// --------------- *
html {
  box-sizing: border-box;
  font-size: 100%;
  height: 100%;
  color: ${props => props.theme.text_primary};
}
body {
  font-family: ${props => props.theme.fontFamily};
  font-size: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.bg_general};

  ${respondTo.sm`
    background-color: ${({ theme }) => theme.bg_general};
  `}
}
#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}
* {
  margin: 0;
  padding: 0;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
img {
max-width: 100%;
  height: auto;
}
a {
  text-decoration: none;
  color: ${props => props.theme.colore_link};
  outline: none;

}
h1{
  font-size: 35px !important;
  ${respondTo.sm`
  font-size: 35px !important;
`}
}
h2{
  font-size: 22px !important;
  ${respondTo.sm`
  font-size: 22px !important;
`}
}

.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-50 {
  margin-bottom: 50px;
}
.md_mb-10 {
  margin-bottom: 10px;

  ${respondTo.md`
    margin-bottom: 0;
  `}
}
.md_mb-20 {
  margin-bottom: 20px;

  ${respondTo.md`
    margin-bottom: 0;
  `}
}
.sm_mt-20 {
  margin-top: 20px;

  ${respondTo.md`
    margin-top: 0;
  `}
}
.md_mb-30 {
  margin-bottom: 30px;

  ${respondTo.md`
    margin-bottom: 0;
  `}
}


p {
  font-size: 18px ;
}

.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.md_mt-10 {
  margin-top: 10px;

  ${respondTo.md`
    margin-top: 0;
  `}
}
.md_mt-20 {
  margin-top: 20px;

  ${respondTo.md`
    margin-top: 0;
  `}
}
.md_mt-30 {
  margin-top: 30px;

  ${respondTo.md`
    margin-top: 0;
  `}
}
.pagination {
  display: flex;
  justify-content: center;
}

ul {
  list-style: none;
  padding: 0;
}

ul.pagination li {
  display: inline-block;
  padding: 0 5px;
  border: 1px solid #e2e2e2;
  display: flex;
  justify-content: center;
  font-size: 25px;
  background-color: #fff;
  cursor: pointer;
}

ul.pagination li a {
  text-decoration: none;
  color: ${({ theme }) => theme.default_color};
  font-size: 20px;
}

ul.pagination li.active a {
  color:  ${({ theme }) => theme.colore_testo_bottoni};
}
ul.pagination li.active {
  background-color: ${({ theme }) => theme.bg_bottoni};
}

ul.pagination li:hover{
  background-color: ${({ theme }) => theme.bg_bottoni};
}
ul.pagination li:hover a{
  color:  ${({ theme }) => theme.colore_testo_bottoni};
}
ul.pagination li a.active {
  background-color: ${({ theme }) => theme.bg_bottoni};
  color:  ${({ theme }) => theme.colore_testo_bottoni};
}

.page-selection {
  width: 48px;
  height: 30px;
  color: #337ab7;
}

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.slick-slide.slick-active{
  outline: none !important;
}
.slick-slide .inSlider{
  height:100%;
}
.inputSelect{
  position: relative;
  svg{
  position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.colorSearch{
  color: ${({ theme }) => theme.default_color};
}

.slick-track
{
    display: flex !important;
}

.slick-slide
{
    height: inherit !important;
    & > div{
      height: 100%;
    }
}
.hoverBtn {
  &:not(:disabled):hover {
    span {
      color: ${({ theme }) => theme.colore_testo_hover_menu_esterno};
    }
    p {
      color: ${({ theme }) => theme.colore_testo_hover_menu_esterno};
    }
    svg {
      color: ${({ theme }) => theme.colore_testo_hover_menu_esterno} !important;
    }
    .maskIcon {
      background-color: ${({ theme }) => theme.colore_testo_hover_menu_esterno};
    }
  }
}

input, button, select, textarea {
  font-family: ${props => props.theme.fontFamily};
}

.only-desktop {
  display: none;

  ${respondTo.sm`
    display: block;
  `}
}

.only-mobile {
  display: block;

  ${respondTo.sm`
    display: none;
  `}
}
`;

export default GlobalStyle;
